import React, {PureComponent, ReactNode} from "react";
import Link from 'next/link';

export type ServicesProps = {
    headline: string,
};

export class Services extends PureComponent<ServicesProps> {

    render(): ReactNode {
        const {
            headline,
        } = this.props

        return (
            <div className={`services px-6 md:px-12 pb-12 md:pb-24`}>
                <h2 className={`text-2xl leading-8 md:text-3xl md:leading-9 font-semibold font-serif text-gray-900 pb-6`}>{headline}</h2>

                <div className={'px-6 md:px-0 grid grid-cols-12 lg:grid-cols-7 gap-x-4 max-w-[1202px]'}>
                    <Link href={'https://www.repareo.de/inspektion/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                            <i className={'fa-kit fa-car-wrench fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/inspektion/'} title={'Inspektion'} className={'text-gray-700 no-underline'}>
                                Inspektion
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/hauptuntersuchung-hu-au/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                        <i className={'fa-kit fa-clipboard-list-check fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/hauptuntersuchung-hu-au/'} title={'HU/AU (TÜV)'} className={'text-gray-700 no-underline'}>
                                HU/AU (TÜV)
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/oelwechsel/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                        <i className={'fa-kit fa-oil-can-drip fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/oelwechsel/'} title={'Ölwechsel'} className={'text-gray-700 no-underline'}>
                                Ölwechsel
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/reifenwechsel/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                        <i className={'fa-kit fa-tire fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/reifenwechsel/'} title={'Reifenwechsel'} className={'text-gray-700 no-underline'}>
                                Reifen&shy;wechsel
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/zahnriemenwechsel/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                        <i className={'fa-kit fa-engine fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/zahnriemenwechsel/'} title={'Zahnriemen-wechsel'} className={'text-gray-700 no-underline'}>
                                Zahnriemen&shy;wechsel
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/kupplung-wechseln/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                            <i className={'fa-kit fa-gears fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/kupplung-wechseln/'} title={'Kupplungs-wechsel'} className={'text-gray-700 no-underline'}>
                                Kupplungs&shy;wechsel
                            </a>
                        </div>
                    </Link>
                    <div className={'hidden lg:block col-span-1'}></div>
                    <Link href={'https://www.repareo.de/klimaservice/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                            <i className={'fa-kit fa-temperature-snow fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/klimaservice/'} title={'Klimaservice'} className={'text-gray-700 no-underline'}>
                                Klima&shy;service
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/bremsen-wechseln/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                            <i className={'fa-kit fa-bagel fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/bremsen-wechseln/'} title={'Bremsenwechsel'} className={'text-gray-700 no-underline'}>
                                <span>Bremsen&shy;wechsel</span>
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/querlenker-wechseln/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                            <i className={'fa-kit fa-steering-wheel fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/querlenker-wechseln/'} title={'Querlenker wechseln'} className={'text-gray-700 no-underline'}>
                                Querlenker wechseln
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/austauschmotor/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                            <i className={'fa-kit fa-engine-warning fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/austauschmotor/'} title={'Motortausch'} className={'text-gray-700 no-underline'}>
                                Motor&shy;tausch
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/motorschaden/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                            <i className={'fa-kit fa-engine-warning fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/motorschaden/'} title={'Motorschaden'} className={'text-gray-700 no-underline'}>
                                <span>Motor&shy;schaden</span>
                            </a>
                        </div>
                    </Link>
                    <Link href={'https://www.repareo.de/autoscheibe/'}>
                        <div className={'cursor-pointer relative col-span-6 shadow-md hover:border-primary-500 hover:shadow-none sm:col-span-4 md:col-span-3 lg:col-span-1 px-6 py-8 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md border-gray-300 border mb-4 md:mb-8  md:aspect-square lg:aspect-auto'}>
                            <i className={'fa-kit fa-toolbox fa-fw text-primary text-3xl mb-3 block'}/>
                            <a href={'https://www.repareo.de/autoscheibe/'} title={'Glasreparatur'} className={'text-gray-700 no-underline'}>
                                Glas&shy;reparatur
                            </a>
                        </div>
                    </Link>
                    <div className={'hidden lg:inline-flex relative flex items-center col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-1 pt-8 pb-16 text-sm leading-5 font-medium md:text-base md:leading-6 md:font-semibold text-gray-900 rounded-md text-center'}>
                        <span>stetig kommen weitere hinzu</span>
                    </div>
                </div>
            </div>
        );
    }
}